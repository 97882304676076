<template>
    <v-navigation-drawer
        :style="'background: ' + store.color + ';'"
        class="sidenav"
        location="left"
        v-model="menu"
        :permanent="!mobile"
        :temporary="mobile"
        :theme="theme"
    >
        <template v-slot:prepend>
            <v-img
                :src="companyLogo"
                class="ma-2"
                cover
                max-width="100%"
                max-height="125"
            >
            </v-img>
            <v-list-item
                :prepend-avatar="store.user.avatar"
                :subtitle="store.name"
                :title="store.user.name"
                lines="two"
            ></v-list-item>
        </template>

        <v-divider></v-divider>

        <v-list density="compact" nav>
            <v-list-item :title="$t('menu.home')" prepend-icon="mdi-receipt-text" to="/" value="home"></v-list-item>
            <v-list-item :title="$t('menu.settings')" prepend-icon="mdi-account-cog" to="/settings"
                         value="account"></v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-2">
                <v-btn :color="logoutColor" block prepend-icon="mdi-logout" variant="text"
                       @click="User.logout($router)">
                    {{ $t("menu.logout") }}
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>

    <v-app-bar v-if="mobile" :color="store.color">
        <v-app-bar-nav-icon :style="'color:'+store.textColor+';'" variant="text"
                            @click.stop="menu = !menu"></v-app-bar-nav-icon>
        <v-toolbar-title :style="'color:'+store.textColor+';'">{{ store.title }}</v-toolbar-title>
    </v-app-bar>

    <v-main>
        <v-container class="pt-16 pb-16">
            <slot></slot>
        </v-container>
        <v-overlay :model-value="loading" class="align-center justify-center">
            <v-progress-circular
                color="white"
                indeterminate
                size="64"
            ></v-progress-circular>
        </v-overlay>
    </v-main>

</template>

<script>
import { useMainStore } from "~/stores/main";
import User from "~/helpers/User";
import LookAndFeel from "~/helpers/LookAndFeel";
import Accounting from "~/helpers/Accounting";
import Cookies from "js-cookie";

export default {
    name: "default",
    data() {
        return {
            loading: false,
            mobile: true,
            menu: false,
            theme: undefined
        };
    },
    async mounted() {
        this.loading = true;
        await LookAndFeel.load();

        if (useMainStore().textColor === "#ffffff") {
            this.theme = "dark";
        }

        if (this.$route.query.login !== undefined) {
            User.verifyLogin(this.$route.query.login, this.$router).finally(() => {
                this.loading = false;
            });
        } else if (this.$route.query.code !== undefined) {
            Accounting.get("/expenses/expense-reports/login/" + Cookies.get("provider") + "?company=" + useMainStore().company + "&code=" + this.$route.query.code)
                .then(resp => {
                    User.verifyLogin(resp.data.token, this.$router).finally(() => {
                        this.loading = false;
                    });
                })
                .catch(() => {
                    User.logout(this.$router);
                    this.loading = false;
                });
        } else {
            User.validateConnectedUser(this.$router)
                .then(() => {
                    this.loading = false;
                });
        }

        this.responsive();

        window.addEventListener("resize", () => {
            this.responsive();
        });
    },
    methods: {
        responsive() {
            this.mobile = window.innerWidth < 1200;
            this.menu = !this.mobile;
        }
    },
    computed: {
        User() {
            return User;
        },
        companyLogo() {
            const store = useMainStore();
            if (store.logo === undefined || store.logo === null) {
                return undefined;
            }
            return store.logo;
        },
        logoutColor() {
            const store = useMainStore();
            if (LookAndFeel.contrast([244, 67, 54], LookAndFeel.hex2Rgb(store.color)) < 7) {
                return "red-lighten-5";
            }
            return "red";
        }
    }
};
</script>

<script setup>
import { useMainStore } from "~/stores/main";
import { getActiveHead } from "unhead";

const store = useMainStore();
</script>

<style scoped>
</style>